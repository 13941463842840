import {LoadingButton} from '@mui/lab';
import {Select} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import {useState} from 'react';

import {OneApartmentApi} from 'api';
import {LocalizedDatePicker} from 'shared/components/LocalizedDatePicker';
import {useToast} from 'shared/hooks';
import {AccountRoles, ServiceModel, ServiceType, TariffTemplateModel} from 'shared/models';
import {bem, checkAccessForRole} from 'shared/utils';

import styles from './ServiceCard.module.scss';

const sn = bem('serviceCard', styles);

type Props = {
  services: ServiceModel;
  onAfterChangeService: () => void;
  id: string;
  title: string;
  pricePerMonth: number;
  tariffTemplates: TariffTemplateModel[];
};

export const ServiceCard = ({services, onAfterChangeService, id, title, pricePerMonth, tariffTemplates}: Props) => {
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [duration, setDuration] = useState<bigint | null>(null)
  const [loadingEnable, setLoadingEnable] = useState(false);
  const [loadingDisable, setLoadingDisable] = useState(false);
  const isDisabled = !checkAccessForRole([AccountRoles.admin, AccountRoles.superAdmin]);
  const {showToast} = useToast();

  const enableService = async () => {
    if ((id && expirationDate) || (id && duration)) {
      try {
        setLoadingEnable(true);
        await OneApartmentApi.enableService(id, ServiceType.BaseFunctionsAccess, {expirationDate, duration});
        if (onAfterChangeService) onAfterChangeService();
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка подключения услуги',
          isError: true,
        });
      } finally {
        setLoadingEnable(false);
      }
    }
  };

  const disableService = async () => {
    if (id) {
      try {
        setLoadingDisable(true);
        await OneApartmentApi.disableService(id, ServiceType.BaseFunctionsAccess);
        setExpirationDate(null);
        if (onAfterChangeService) onAfterChangeService();
      } catch (err) {
        showToast({
          title: 'Ошибка',
          message: 'Ошибка отключения услуги',
          isError: true,
        });
      } finally {
        setLoadingDisable(false);
      }
    }
  };

  const onDateChange = (date: Date | string | null) => {
    const preparedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setExpirationDate(preparedDate);
  };

  return (
    <div className={styles.serviceCard}>
      <h4 className={sn('title')}>{title}</h4>
      <div className={sn('panel')}>
        <div className={sn('columns')}>
          <div className={sn('column')}>
            <span>Услуга {services.isActive ? 'подключена' : 'отключена'}</span>
            {services.isActive && <span>{services.startDate}</span>}
          </div>
          <div className={sn('column')}>
            <span>{services.isActive ? 'Услуга активна до' : 'Выберите дату окончания услуги'}</span>
            <span>
              <LocalizedDatePicker
                value={services.isActive ? dayjs(services.expirationDate).toDate() : null}
                onDateChange={onDateChange}
                disabled={services.isActive}
              />
            </span>
          </div>
          <div className={sn('column')}>
            <span>Или укажите оплачиваемый период</span>
            <Select
              id="tariffTemplate"
              value={duration}
              displayEmpty={true}
              onChange={(event) => setDuration(event.target.value as bigint)}
            >
              <MenuItem value="">
                <em>Не выбрано</em>
              </MenuItem>
              {tariffTemplates.map((x) => (
                <MenuItem key={x.duration} value={x.duration}>
                  {`${x.name} (${x.duration * pricePerMonth}₽)`}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={sn('column')}>
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={loadingEnable}
              className={sn('button')}
              disabled={isDisabled || (!expirationDate && !duration)}
              onClick={enableService}
            >
              Подключить
            </LoadingButton>
          </div>
          <div className={sn('column')}>

            <LoadingButton
              variant="contained"
              color="warning"
              loading={loadingDisable}
              className={sn('button')}
              disabled={isDisabled || !services.isActive}
              onClick={disableService}
            >
              Отключить
            </LoadingButton>
          </div>

        </div>

      </div>
    </div>
  );
};
